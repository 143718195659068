import type { AppState } from '365.pwa.types.ts';

declare module 'o365.pwa.declaration.shared.dexie.objectStores.PWAState.d.ts' {
    export class PWAState {
        static objectStoreDexieSchema: string;

        appId: string;
        appState: AppState;
        hasDatabaseConnection: boolean;
        isAppInstalled: boolean;
        hasHtmlUpdate: boolean;
        hasAppResourceUpdate: boolean;
        hasServiceWorkerUpdate: boolean;
        hasLocalData: boolean;
        debugUi: boolean;
        debugMergeProc: boolean;
        skipCheckIn: boolean;
        lastSync: { [key: string]: Date };

        constructor(appId: string, appState: AppState, hasDatabaseConnection: boolean);

        readonly hasAppUpdateAvailable: boolean;

        save(): Promise<void>;
        delete(): Promise<void>;
        forceReload(): Promise<PWAState | null>;
    }
}
